<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách người lao động thất nghiệp</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :selectOptions="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        ></vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} Người lao động</p>

        <!-- {{ dataInvalid.length }} -->
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách người lao động thất nghiệp không hợp lệ</h4>

        </div>
        <div class="listwork__head__02__right">
          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </div>
      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
          vgtDisabled="true"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->
            <!-- ===========================Họ và Tên==========================-->
            <span
              v-if="props.column.field == 'name' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Name'||!props.row.name,dataErr:!props.row.name,}"
            >
              {{ props.row.name }}
            </span>
            <span v-if="props.column.field == 'name' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Name'"
                v-model="props.row.name"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.name, 'name', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Name'"
              >{{ props.row.name }}</span>
            </span>
            <!-- ===========================Họ và Tên==========================-->

            <!-- ===========================Ngày sinh========================= -->
            <span
              v-if="props.column.field == 'birthDay'"
            >
              {{ props.row.birthDay }}
            </span>

            <!-- ===========================Ngày sinh========================= -->

            <!-- ========================Giới tính========================= -->
            <span
              v-if="props.column.field == 'gender'"
            >
              {{ props.row.gender }}
            </span>

            <!-- ===========================Giới tính================================ -->

            <!-- ===========================Địa chỉ================================== -->
            <span
              v-if="props.column.field == 'address' "
            >
              {{ props.row.address }}
            </span>

            <!-- ===========================Địa chỉ================================== -->

            <!-- ===========================Số điện thoại============================ -->
            <span
              v-if="props.column.field == 'phoneNumber' && editvalueErr === false"
            >
              {{ props.row.phoneNumber }}
            </span>

            <!-- ===========================Số điện thoại============================ -->

            <!-- =========================Tỉnh/Thành phố============================= -->
            <span
              v-if="props.column.field == 'province' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Province'||!props.row.province,dataErr:!props.row.province,}"
            >
              {{ props.row.province }} {{ props.row.isDisabledDis }}
            </span>
            <span v-if="props.column.field == 'province' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Province'"
                v-model="props.row.province"
                :reduce="title => title.name"
                label="name"
                :options="provinceList || []"
                @input="changeVselectCellvalue(props.row.province, 'province', props.row, 'isDisabledDis')"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Province'"
              >{{ props.row.province }}</span>
            </span>

            <!-- =========================Tỉnh/Thành phố============================= -->

            <!-- =========================Huyện/Quận============================= -->
            <span
              v-if="props.column.field == 'district' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'District'||!props.row.district,dataErr:!props.row.district,}"
            >
              {{ props.row.district }}
            </span>
            <span v-if="props.column.field == 'district' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Province'"
                v-model="props.row.district"
                :reduce="item => item.name"
                label="name"
                :disabled="props.row.isDisabledDis"
                :options="districtList || []"
                @input="changeVselectCellvalue(props.row.district, 'district', props.row, 'isDisabledWard' )"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Province'"
              >{{ props.row.district }}</span>
            </span>

            <!-- =========================Huyện/Quận============================= -->

            <!-- =========================Xã/Phường============================= -->
            <span
              v-if="props.column.field == 'ward' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Ward'||!props.row.ward,dataErr:!props.row.ward,}"
            >
              {{ props.row.ward }}
            </span>
            <span v-if="props.column.field == 'ward' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'Province'"
                v-model="props.row.ward"
                :reduce="item => item.name"
                label="name"
                :disabled="props.row.isDisabledWard"
                :options="wardList || []"
                @input="changeVselectCellvalue(props.row.ward, 'ward', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'Province'"
              >{{ props.row.ward }}</span>
            </span>
            <!-- =========================Xã/Phường============================= -->

          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} Người lao động</p>
      </div>
      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm người lao động
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    VSelect,
    VueGoodTable,
    BButton,
    DateTimePicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },

  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      dataInvalid: [],
      dataNoInvalid: [],
      columns: [
        {
          label: 'HỌ VÀ TÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'GIỚI TÍNH',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'SỐ ĐIỆN THOẠI',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'TỈNH/THÀNH PHỐ',
          field: 'province',
          sortable: false,
        },
        {
          label: 'HUYỆN/QUẬN',
          field: 'district',
          sortable: false,
        },
        {
          label: 'XÃ/PHƯỜNG',
          field: 'ward',
          sortable: false,
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'HỌ VÀ TÊN',
          field: 'name',
          sortable: false,
        },
        {
          label: 'NGÀY SINH',
          field: 'birthDay',
          sortable: false,
        },
        {
          label: 'GIỚI TÍNH',
          field: 'gender',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'SỐ ĐIỆN THOẠI',
          field: 'phoneNumber',
          sortable: false,
        },
        {
          label: 'TỈNH/THÀNH PHỐ',
          field: 'province',
          sortable: false,
        },
        {
          label: 'HUYỆN/QUẬN',
          field: 'district',
          sortable: false,
        },
        {
          label: 'XÃ/PHƯỜNG',
          field: 'ward',
          sortable: false,
        },
      ],
      editvalueErr: false, // bật tắt chỉnh sửa
      provinceList: [],
      districtList: [],
      wardList: [],
      disableProp: false,
      idProvince: 0,
      idDistrict: 0,
      isDisabledDis: '',
      isDisabledWard: '',
      index: '',
    }
  },
  computed: {
    ...mapGetters('umployedWorker', ['validWork', 'validNoWork', 'dataTooltip']),
  },

  watch: {
  },
  created() {
    const array = []
    this.dataInvalid = this.validWork
    this.validNoWork.forEach(e => {
      array.push(e = {
        ...e,
        isDisabledDis: true,
        isDisabledWard: true,
      })
    })
    this.dataNoInvalid = array
    this.checkErrorUser(this.dataTooltip)
    this.fetchComboboProvince()
  },

  methods: {
    ...mapActions('umployedWorker', ['getApiExcel']),

    // Tỉnh thành phố
    async fetchComboboProvince() {
      const { data } = await axiosApiInstance.get(ConstantsApi.COMBOBOX_CITY)
      this.provinceList = data
    },

    // Quận huyện
    async fetchComboboxDistrict(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_DISTRICT}${a}`)
      this.districtList = data
    },

    // Xã phường
    async fetchComboboxWard(a) {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.COMBOBOX_WARD}${a}`)
      this.wardList = data
    },

    // BẬT tắt chỉnh sửa
    onOffEdit() {
      this.editvalueErr = !this.editvalueErr
    },

    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },

    changeVselectCellvalue(event, field, rowData, isDisable) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
      // Kiểm tra tới cột tỉnh thành phố
      if (field === 'province') {
        // Kiểm tra cột tỉnh thành phố có null hay không
        if (this.dataNoInvalid[rowData.originalIndex][field]) {
          this.dataNoInvalid[rowData.originalIndex][isDisable] = false
          this.isDisabledDis = isDisable
          const array = this.provinceList.filter(e => e.name === event)
          const a = array[0]
          this.idProvince = a.id
          this.fetchComboboxDistrict(this.idProvince)
        } else {
          const district = 'district'
          const isDisabledWard = 'isDisabledWard'
          const ward = 'ward'
          this.dataNoInvalid[rowData.originalIndex][ward] = null
          this.dataNoInvalid[rowData.originalIndex][district] = null
          this.dataNoInvalid[rowData.originalIndex][isDisable] = true
          this.dataNoInvalid[rowData.originalIndex][isDisabledWard] = true
        }
      }

      // Kiểm tra tới cột quận huyện
      if (field === 'district') {
        // Kiểm tra cột quận huyện null hay không
        if (this.dataNoInvalid[rowData.originalIndex][field]) {
          this.dataNoInvalid[rowData.originalIndex][isDisable] = false
          this.isDisabledWard = isDisable
          const array = this.districtList.filter(e => e.name === event)
          const id = array[0]
          this.idDistrict = id.id
          this.fetchComboboxWard(this.idDistrict)
        } else {
          const ward = 'ward'
          this.dataNoInvalid[rowData.originalIndex][ward] = null
          this.dataNoInvalid[rowData.originalIndex][isDisable] = true
        }
      }
    },

    // nút check
    async CheckdataNoInvalid() {
      const array = []
      const array1 = []
      this.dataNoInvalid.forEach(e => {
        if (e.province === null || e.district === null || e.ward === null) {
          array.push(e = {
            ...e,
            province: null,
            district: null,
            ward: null,
          })
        } else {
          array.push(e)
        }
      })
      const model = {
        listExcel: array,
        isValidate: true,
      }
      this.$showAllPageLoading()
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
        this.dataNoInvalid.forEach(e => {
          array1.push(e = {
            ...e,
            isDisabledDis: true,
            isDisabledWard: true,
          })
        })
        this.dataNoInvalid = array1
      } else {
        this.dataNoInvalid = []
      }
      this.$hideAllPageLoading()
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'work-umployed' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.$showAllPageLoading()
      this.getApiExcel(model).then(res => {
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.dataInvalid = []
            this.dataNoInvalid = []
            this.$router.push({ name: 'work-umployed' })
          }
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },
  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
